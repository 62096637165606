:root {
  --black: #000b0b;
  --black-dropdown-menu: #172424;

  --white: #ffffff;

  --light-blue-polls1: #bee7e7;
  --light-blue-polls2: #eff5f5;
  --light-blue-polls2-hover: #cceef0;
  --light-blue-polls2-focused: #cceaea;
  --light-blue: #83dbdb;
  --light-blue-focused: #65e4e4;
  --blue: #00a8b4;
  --violet: #772feb;
  --dark-blue: #20334f;

  --grey: #e4e7e7;
  --grey-focused: #616d6d;
  --grey-text1: #798686;
  --grey-text2: #303635;
  --grey-text3: #a1aaaa;
  --grey-arrow: #dfe2e2;
  --grey-arrow-focused: #ccd5d5;
  --grey-icon-focused: #616d6d;
  --grey-menu-focused: #f0f4f4;
  --grey-filter-focused: #f8f9f9;
  --grey-bg: #f8f9f9;
  --grey-hover: #344141;

  --red: #ff5555;

  --red-alert: #d60000;
  --green-alert: #609f62;
  --green: #34ad12;

  --training-status-expired: #e82f17;
  --training-status-end: #176ce8;

  --green-palette-1-green: #00a8b4;
  --green-palette-2-green: #66cbd2;
  --green-palette-3-green: #99dce1;
  --green-palette-4-green: #cceef0;
  --green-palette-5-green: #f2fbfb;

  --grayscale-palette-1-gray: #303635;
  --grayscale-palette-2-gray: #798686;
  --grayscale-palette-3-gray: #a1aaaa;
  --grayscale-palette-4-gray: #e4e7e7;
  --grayscale-palette-5-gray: #f8f9f9;

  /* Screen sizes */
  --sm: 640px;
  --md: 1399px;
}
