@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/mixins';
@import 'src/styles/media';
@import '@ionic/angular/css/ionic-swiper';
@import '~swiper/swiper-bundle.min.css';
@import '~swiper/swiper.min.css';
@import '~swiper/modules/thumbs/thumbs.min.css';
@import '~swiper/modules/zoom/zoom.min.css';

@font-face {
  font-family: 'Proxima Nova';
  src: url('assets/fonts/ProximaNova-Regular.otf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  @include user-select(none);
}

:root {
  input,
  textarea {
    @include user-select(text);
  }
}

a {
  text-decoration: none;
}

ion-content {
  --background: var(--grey-bg);
  --overflow: auto;
  background: var(--ion-toolbar-background, var(--ion-background-color, var(--grey-bg))) !important;
  overflow: auto;

  @include scrollbar(8px);

  .inner-scroll {
    scrollbar-width: thin;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: var(--black);
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: background-color 5000s ease-in-out 0s;
}

ion-alert.custom-alert {
  font-family: 'Proxima Nova', serif;
  --backdrop-opacity: 0.8;
}

ion-action-sheet {
  .action-sheet-button-inner {
    color: #00a8b4;
  }
}

button.alert-button.alert-button {
  color: var(--blue);
  font-family: 'Proxima Nova', serif;
}

.action-sheet-menu {
  .action-sheet-group {
    background: rgba(23, 36, 36, 0.9);
  }
  .action-sheet-cancel.sc-ion-action-sheet-ios::after {
    background: rgba(23, 36, 36, 0.9);
  }

  .action-sheet-button-inner {
    color: var(--white);
    justify-content: flex-start;
  }

  .action-sheet-cancel {
    .action-sheet-button-inner {
      justify-content: center;
    }
  }
}

.image-comments-view {
  --width: 100%;
  --height: 100%;
}
.modal-post-view {
  ion-content {
    --background: var(--white);
  }
  .modal-view {
    height: 100% !important;
    ion-grid {
      height: 100%;
    }
    ion-row {
      height: 100%;
      margin: 0;
    }
    .post-view {
      flex: 1;
      margin-bottom: 0;

      .block {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

ion-toolbar {
  padding-left: 0 !important;
  padding-right: 0 !important;
  --padding-end: 0;
  --padding-start: 0;
}

ion-toast {
  font-family: 'Proxima Nova';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--light-blue-polls2);
  --border-radius: 4px;
  --ion-safe-area-bottom: calc(50px + env(safe-area-inset-bottom));
}

.custom-ion-toast {
  --width: 288px;
}
.custom-ion-toast::part(message) {
  --width: 248px;
}
.custom-ion-toast::part(icon) {
  padding-right: 11px;
  border-right: 1px solid #eff5f5;
  --width: 40px;
}

//to make modal handle button invisible
ion-modal::part(handle) {
  opacity: 0;
}

ion-popover {
  --background: #ffffff;
  --box-shadow: 0px 0px 20px rgba(0, 11, 11, 0.1);
  border-radius: 15px;
  --offset-x: -11px;
}

ion-datetime.ion-color-primary {
  --ion-color-base: var(--blue) !important;
  --background: var(--white);
  border-radius: 4px;
  filter: drop-shadow(0px 0px 16px rgba(19, 32, 77, 0.07));

  ion-buttons {
    height: 50px;
  }

  ion-button {
    --border-radius: 8px;
    height: 50px;
  }
}

@media only screen and (max-width: 768px) {
  //to make modal sheet fullscreen
  .modal-photo-gallery {
    --height: 100%;
    --border-radius: 0;
  }
  .custom-ion-toast {
    --width: 92%;
  }

  .custom-ion-toast::part(icon) {
    border-width: 2px;
  }
}

@include media-desktop {
  .modal-post-view {
    --border-radius: 10px;
    --width: 645px;
    --height: 80vh;

    .modal-view {
      .post-view .block {
        app-post-comment:last-child .comment {
          margin-bottom: 0;
        }
      }
    }
  }

  .modal-pdf-view {
    --width: 100%;
    --height: 100%;
    --max-width: 1120px;
    --max-height: 850px;
    --min-width: 90%;
  }
}

.user-select-text {
  &,
  * {
    @include user-select(text);
  }
}

.user-select-none {
  &,
  *:not(.user-select-text) {
    cursor: text;
    @include user-select(none);
  }
}

.btn-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.link {
  font-family: 'Proxima Nova', serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--blue);

  &:active {
    color: var(--blue);
  }
}

.nav-link {
  color: inherit;
  text-decoration: none;

  @include media-desktop {
    &:hover,
    &:active {
      * {
        color: var(--blue);
      }
    }
  }
}

@include media-desktop {
  ion-toast {
    --end: 33px;
    --start: none;
    --ion-safe-area-bottom: 8px;
  }
  .image-comments-view {
    --width: 1210px;
    --height: 624px;
    --max-width: 1210px;
    --min-width: 1210px;
    --max-height: 624px;
    --border-radius: 6px;
  }
  .modal-pdf-view {
    --width: 100%;
    --height: 100%;
    --max-width: 1120px;
    --max-height: 850px;
    --min-width: 90%;
  }
  .fullscreen-gallery-modal {
    --width: 100%;
    --height: 100%;
    --max-width: 100%;
    --min-width: 100%;
    --max-height: 100%;
    --border-radius: 0;
  }

  .link:hover {
    color: var(--light-blue);
  }
}

.no-drag {
  user-select: none;
  -webkit-user-drag: none;
}

img {
  @extend .no-drag;
}

.keyboard-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.alert-wrapper {
  .alert-title {
    font-size: 16px;
    padding: 0 10px;
  }

  .alert-sub-title {
    font-size: 13px;
  }
}

.image-comments-view,
.edit-post-modal {
  --overflow: visible;

  .ion-page {
    overflow: visible;
  }
}

.dot-divider {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: var(--grayscale-palette-3-gray);
}
