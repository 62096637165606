@mixin user-select($value) {
  -webkit-touch-callout: $value; /* iOS Safari */
  -webkit-user-select: $value; /* Safari */
  -khtml-user-select: $value; /* Konqueror HTML */
  -moz-user-select: $value; /* Firefox */
  -ms-user-select: $value; /* Internet Explorer/Edge */
  user-select: $value; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

@mixin image-overlay() {
  position: relative;

  &:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    content: '';
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(0, 11, 11, 0.52) -2.01%,
      rgba(0, 11, 11, 0.42) 2.27%,
      rgba(0, 11, 11, 0.32) 10.52%,
      rgba(0, 11, 11, 0.1) 21.67%,
      rgba(0, 11, 11, 0.06) 28.45%
    );
  }
}

@mixin scrollbar($width) {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-track {
    background: var(--white);
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 $width var(--grey);
    border-radius: 4px;
    background-color: var(--grey);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--grey-text3);
  }
}
