.base-h1-header {
  color: var(--black);
  text-transform: uppercase;
  @extend .font-styles, .font-medium, .h1-header;
}

.base-h2-menu {
  color: var(--black);
  @extend .font-styles, .font-thin, .h2-menu;
}

.base-h3-body {
  @extend .font-styles, .font-thin, .h3-body;
}

.base-h4-name {
  color: var(--black);

  @extend .font-styles, .font-medium, .h4-name;
}

.base-h5-post {
  @extend .font-styles, .font-thin, .h5-post;
}

.base-label {
  @extend .font-styles, .font-bold, .label;
}

.base-number-phone {
  color: var(--grey-text1);
  @extend .font-styles, .font-medium, .number-phone;
}

.base-like {
  color: var(--grey-text2);
  @extend .font-styles, .font-thin, .like;
}

.base-date {
  color: var(--grey-text1);

  @extend .font-styles, .font-thin, .date;
}

.base-alert {
  @extend .font-styles, .font-medium, .alert;
}

.base-text-alert {
  @extend .font-styles, .font-thin, .text-alert;
}

.base-mob-post {
  color: var(--grey-text2);
  @extend .font-styles, .font-thin, .mob-post;
}

.base-mob-input {
  color: var(--grey-text2);
  @extend .font-styles, .font-thin, .mob-input;
}

.base-mob-label {
  @extend .font-styles, .font-bold, .mob-label;
}

.base-qty {
  color: var(--white);
  @extend .font-styles, .font-medium, .qty;
}

.base-mob-body {
  color: var(--grey-text2);
  @extend .font-styles, .font-thin, .mob-body;
}

.base-login-title {
  @extend .font-styles, .font-bold, .login-title;
}

.base-login-text {
  color: var(--grey-text2);

  @extend .font-styles, .font-thin, .login-text;
}

.base-file-title {
  color: var(--black);

  @extend .font-styles, .font-medium, .file-title;
}

.base-file-subtitle {
  color: var(--grey-text1);

  @extend .font-styles, .font-thin, .file-subtitle;
}

.base-notification {
  color: var(--grey-text2);

  @extend .font-styles, .font-thin, .notification;
}

.button-text-1 {
  color: var(--white);
  @extend .font-styles, .text-1, .font-medium;
}

.base-h6-btn-small {
  color: var(--grey-text1);
  @extend .font-styles, .font-bold, .h6-btn-small;
}

.button-h6-btn-small {
  color: var(--white);
  @extend .font-styles, .h6-btn-small, .font-medium;
}

.button-btn-main-small {
  @extend .font-styles, .btn-main-small;
}

.button-btn-filter {
  color: var(--black);
  @extend .font-styles, .font-medium, .btn-filter-font;
}

.font-styles {
  font-family: 'Proxima Nova', serif;
}

.font-bold {
  font-style: normal;
  font-weight: 700;
}

.font-medium {
  font-style: normal;
  font-weight: 600;
}

.font-thin {
  font-style: normal;
  font-weight: 400;
}

.h1-header {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.h2-menu {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.h3-body {
  font-size: 15px;
  line-height: 20px;
}

.h4-name {
  font-size: 14px;
  line-height: 16px;
}

.h5-post {
  font-size: 13px;
  line-height: 16px;
}

.label {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.number-phone {
  font-size: 12px;
  line-height: 12px;
}

.like {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.06em;
}

.date {
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.text-alert {
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.5px;
}

.alert {
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.mob-post {
  font-size: 14px;
  line-height: 14px;
}

.mob-input {
  font-size: 14px;
  line-height: 16px;
}

.mob-label {
  font-size: 14px;
  line-height: 16px;
}

.qty {
  font-size: 12px;
  line-height: 10px;
  letter-spacing: 0.5px;
}

.mob-body {
  font-size: 13px;
  line-height: 16px;
}

.login-title {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.login-text {
  font-size: 12px;
  line-height: 20px;
}

.text-1 {
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 1.5px;
}

.h6-btn-small {
  font-size: 13px;
  line-height: 14px;
  text-transform: lowercase;
  letter-spacing: 1.5px;
}

.btn-main-small {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.btn-filter-font {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.file-title {
  font-size: 14px;
  line-height: 16px;
}

.file-subtitle {
  font-size: 12px;
  line-height: 12px;
}

.notification {
  font-size: 15px;
  line-height: 17px;
}
